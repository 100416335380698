import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Image from "../components/Image"

const ValuePropositionTwoColumns = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "vertical-side.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <section id="how-does-it-work" className="section py-75">
      <div className="container columns">
        <div className="column is-flex is-flex-direction-column">
          <Image className="vertical-rounded-right-border" data={data} />
        </div>

        <div className="column ml-6 is-flex is-flex-direction-column is-justify-content-center">
          <div>
            <h2 className="is-size-2 title mb-6 ml-70">Comment ça marche ?</h2>
          </div>
          <div className="is-flex mb-6">
            <div className="round-tag has-text-white has-background-primary is-size-4 has-text-weight-medium p-2 is-flex is-justify-content-center mr-5">
              1
            </div>
            <div>
              <h4 className="title is-4">
                MonApport.com investit dans votre bien à vos côtés :
              </h4>
              <p className="subtitle">
                Votre ex-conjoint souhaite vendre le bien familial et vous ne
                disposez pas des ressources pour conserver votre appartement ?
                Mon apport se substitue au conjoint vendeur en investissant
                jusqu’à 20% de la valeur totale de votre appartement.
              </p>
            </div>
          </div>
          <div className="is-flex mb-6">
            <div className="round-tag has-text-white has-background-primary is-size-4 has-text-weight-medium p-2 is-flex is-justify-content-center mr-5">
              2
            </div>
            <div>
              <h4 className="title is-4">Rester chez vous :</h4>
              <p className="subtitle">
                En échange de notre investissement de 20%, MonApport.com devient
                co-propriétaire de votre bien. Nous devenons co-propriétaire
                minoritaire à hauteur de 30%. A ce titre nous ne pouvons
                interférer dans la vie du bien. Habitation, location,
                rénovation… Vous êtes chez vous.
              </p>
            </div>
          </div>
          <div className="is-flex">
            <div className="round-tag has-text-white has-background-primary is-size-4 has-text-weight-medium p-2 is-flex is-justify-content-center mr-5">
              3
            </div>
            <div>
              <h4 className="title is-4">
                C’est quand vous vendez votre bien que MonApport.com se
                rémunère.
              </h4>
              <p className="subtitle">
                Vous êtes le propriétaire, vous décidez seul du moment de la
                vente du bien dans un délai de 10 ans. Si vous souhaitez
                conserver le bien à l’issue de ce délai, vous pourrez racheter
                les parts de MonApport.com.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ValuePropositionTwoColumns
