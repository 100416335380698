import React from "react"
import { Link } from "gatsby"

import Nav from "../components/Nav"

const Hero = () => {
  return (
    <div className="hero is-fullheight hero-background has-text-white">
      <div className="hero-head">
        <Nav />
      </div>
      <div className="hero-body">
        <div className="container columns">
          <div className="column p-5 is-two-thirds">
            <h1 className="title is-size-2 has-text-weight-bold has-text-white">
              En instance de séparation ?
              <br />
              Restez chez vous grâce à MonApport.com
            </h1>
            <h2 className="subtitle is-size-3 has-text-weight-light has-text-white">
              MonApport.com investit à vos côtés pour vous permettre de
              conserver votre bien malgré les changements dans votre vie
              personnelle.
            </h2>
            <Link
              to="#typeform"
              className="button has-boxshadow is-large is-primary has-text-weight-bold mt-5"
            >
              Déposez votre dossier
            </Link>
          </div>
          <div className="column"></div>
        </div>
      </div>
    </div>
  )
}

export default Hero
